import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface WithNavigationProps {
    navigate: (to: string) => void;
}

interface WithParams {
    params: any;
}

export function withNavigation<P extends WithNavigationProps>(Component: React.ComponentType<P>): React.FC<Omit<P, 'navigate'>> {
    return (props: Omit<P, 'navigate'>) => {
        const navigatable: WithNavigationProps = {
            navigate: useNavigate()
        };
        const newProps: P = { ...props, ...navigatable } as P;
        return <Component {...newProps} />;
    };
}

export function NavigationBar(): React.ReactElement {
    const navigate = useNavigate();
    return (
        <>
            <IconButton onClick={() => { navigate(-1) }}>
                <ArrowBack> Going Back</ArrowBack>
            </IconButton>
        </>
    );
}


export function withParams<P extends WithParams>(Component: React.ComponentType<P>): React.FC<Omit<P,'params'>> {
    return (props: Omit<P, 'params'>) => {
        const params = useParams();
        const newParams: P = { ...props, params } as P;
        return <Component {...newParams}/>;
    };
}