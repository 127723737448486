import React, { Component } from "react";
import { Button, IconButton, Typography } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { ws } from "./websocket";
import GroupBox from "./GroupBox";
import { withParams } from "./Wrappers";

class KeyShifter extends Component <{
    params: { pianoId: string };
},
{
    key: number,
            startPoint: number,
            endPoint: number,
            id: NodeJS.Timeout | null,
            tId: NodeJS.Timeout| null,
            pianoId: string,
            componentId: string,
}, any>{
    constructor(props: {
        params: { pianoId: string };
        }) {
        super(props);
        this.state = {
            key: 0,
            startPoint: 0,
            endPoint: 0,
            id: null,
            tId: null,
            pianoId: props.params.pianoId,
            componentId: Math.random().toString(36).substring(7),
        };
    }
    
    componentWillUnmount() {
        const { pianoId, componentId } = this.state;
        ws.send(
            JSON.stringify({
                command: "unsubscribe",
                pianoId: pianoId,
            })
        );
        ws.removeCallbacks(componentId);
    
    }

    componentDidMount() {
        const { pianoId, componentId } = this.state;

        ws.addCallbacks(
            componentId,
            () => {
                ws.send(
                    JSON.stringify({
                        command: "setKey",
                        key: 0,
                        pianoId: pianoId,
                    })
                );
                ws.send(
                    JSON.stringify({
                        command: "subscribe",
                        pianoId: pianoId,
                    })
                );
            },
            (event) => {
                console.log("WebSocket connection closed:", event);
                // setError(new Error('WebSocket connection closed'));un
                // Handle your close event here
            },
            (error) => {
                console.log("WebSocket error:", error);
                // setError(error);
                // Handle your error here
            }
        );
        ws.addMessageCallback(componentId, pianoId, (event) => {
            const { Command, Payload } = JSON.parse(event.data);
            switch (Command) {
                case "displayKey":
                    const [k, s, e] = Payload.split(",");
                    this.setState({
                        key: parseInt(k),
                        startPoint: parseInt(s),
                        endPoint: parseInt(e),
                    });
                    break;
                case "shiftKey":
                    const json = JSON.parse(Payload);
                    this.setState({
                        startPoint: json.Start,
                        endPoint: json.End,
                    });
                    break;
                default:
                    break;
            }
        });
    }

    saveConfigs = () => {
        const { pianoId } = this.state;
        ws.send(
            JSON.stringify({
                command: "saveConfig",
                pianoId: pianoId,
            })
        );
    };

    handleIntervalEvent = (id:string) => {
        let newKey = this.state.key;
        let command = "shiftKey";
        let reference = null;
        let direction = null;
        switch (id) {
            case "startLeft":
                reference = 0;
                direction = -1;
                break;
            case "startRight":
                reference = 0;
                direction = 1;
                break;
            case "endLeft":
                reference = 1;
                direction = -1;
                break;
            case "endRight":
                reference = 1;
                direction = 1;
                break;
            case "keyLeft":
                reference = 0;
                direction = 0;
                newKey = this.state.key - 1;
                command = "setKey";
                break;
            case "keyRight":
                reference = 0;
                direction = 0;
                newKey = this.state.key + 1;
                command = "setKey";
                break;
            default:
                return;
        }
        if (newKey > 60) {
            newKey = 60;
        } else if (newKey < 0) {
            newKey = 0;
        }
        const { pianoId } = this.state;
        ws.send(
            JSON.stringify({
                command: command,
                key: newKey,
                reference: reference,
                direction: direction,
                pianoId: pianoId,
            })
        );
    };

    handlePositionChangeDown = (event :{
        type: string;
        currentTarget: { id: string };
        preventDefault: () => void;
    }) => {
        if (event.type === "touchstart" || event.type === "mousedown") {
            event.preventDefault();
        }
        const targetId = event.currentTarget.id;
        console.log(event.type + " event on " + targetId);
        this.handleIntervalEvent(targetId);
        const { id, tId } = this.state;
        if (id || tId) {
            return;
        }
        const timerId = setTimeout(() => {
            const iId = setInterval(() => {
                console.log("targetid " + targetId + " for key" + this.state.key);
                this.handleIntervalEvent(targetId);
            }, 100);
            this.setState({
                id: iId,
            });
        }, 500);
        this.setState({
            tId: timerId,
        });
    };

    handlePositionChangeUp = (event: {
        type: string;
        currentTarget: { id: string };
        preventDefault: () => void;
    }) => {
        if (event.type === "touchend" || event.type === "mouseup") {
            event.preventDefault();
        }
        console.log("up event on " + event.currentTarget.id);
        const { id, tId } = this.state;
        if (id) {
            clearInterval(id);
            this.setState({
                id: null,
            });
        }
        if (tId) {
            clearTimeout(tId);
            this.setState({
                tId: null,
            });
        }
    };

    render() {
        const { key, startPoint, endPoint } = this.state;

        return (
            <GroupBox header="Key Shifter" maxHeightPercentage={80}>

                <IconButton
                    id="keyLeft"
                    value={key}
                    onMouseUp={this.handlePositionChangeUp}
                    onMouseDown={this.handlePositionChangeDown}
                    onTouchStart={this.handlePositionChangeDown}
                    onTouchEnd={this.handlePositionChangeUp}
                    style={{
                        borderRadius: "50%",
                        fontSize: "4rem",
                        backgroundColor: "white",
                    }}
                >
                    <ArrowLeft />
                </IconButton>
                <IconButton
                    id="keyRight"
                    value={key}
                    onMouseUp={this.handlePositionChangeUp}
                    onMouseDown={this.handlePositionChangeDown}
                    onTouchStart={this.handlePositionChangeDown}
                    onTouchEnd={this.handlePositionChangeUp}
                    style={{
                        borderRadius: "50%",
                        fontSize: "4rem",
                        backgroundColor: "white",
                    }}
                >
                    <ArrowRight />
                </IconButton>

                <Typography
                    variant="body1"
                    style={{
                        marginBottom: "20px",
                    }}
                >
                    Key : {key + 1}
                </Typography>

                <IconButton
                    id="startLeft"
                    value={key}
                    onMouseUp={this.handlePositionChangeUp}
                    onMouseDown={this.handlePositionChangeDown}
                    onTouchStart={this.handlePositionChangeDown}
                    onTouchEnd={this.handlePositionChangeUp}
                    style={{
                        borderRadius: "50%",
                        fontSize: "4rem",
                        backgroundColor: "white",
                    }}
                >
                    <ArrowLeft />
                </IconButton>
                <IconButton
                    id="startRight"
                    value={key}
                    onMouseUp={this.handlePositionChangeUp}
                    onMouseDown={this.handlePositionChangeDown}
                    onTouchStart={this.handlePositionChangeDown}
                    onTouchEnd={this.handlePositionChangeUp}
                    style={{
                        borderRadius: "50%",
                        fontSize: "4rem",
                        backgroundColor: "white",
                    }}
                >
                    <ArrowRight />
                </IconButton>

                <Typography
                    variant="body1"
                    style={{
                        marginBottom: "20px",
                    }}
                >
                    Start Position : {startPoint}
                </Typography>
                <IconButton
                    id="endLeft"
                    value={key}
                    onMouseUp={this.handlePositionChangeUp}
                    onMouseDown={this.handlePositionChangeDown}
                    onTouchStart={this.handlePositionChangeDown}
                    onTouchEnd={this.handlePositionChangeUp}
                    style={{
                        borderRadius: "50%",
                        fontSize: "4rem",
                        backgroundColor: "white",
                    }}
                >
                    <ArrowLeft />
                </IconButton>
                <IconButton
                    id="endRight"
                    value={key}
                    onMouseUp={this.handlePositionChangeUp}
                    onMouseDown={this.handlePositionChangeDown}
                    onTouchStart={this.handlePositionChangeDown}
                    onTouchEnd={this.handlePositionChangeUp}
                    style={{
                        borderRadius: "50%",
                        fontSize: "4rem",
                        backgroundColor: "white",
                    }}
                >
                    <ArrowRight />
                </IconButton>
                <Typography
                    variant="body1"
                    style={{
                        marginBottom: "20px",
                    }}
                >
                    End Position : {endPoint}
                </Typography>
                <br></br>
                <Button
                    onClick={this.saveConfigs}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}
                >
                    Save
                </Button>
            </GroupBox>
        );
    }
}

export default withParams(KeyShifter);