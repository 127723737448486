import { Box, Typography } from "@material-ui/core";
import React from "react";

interface GroupBoxProps {
    children: React.ReactNode;
    maxHeightPercentage?: number;
    header?: string;
    innerBackgroundColor?: string;
}

class GroupBox extends React.Component<GroupBoxProps, {}> {

    contentRef: HTMLDivElement | null = null;

    render() {
        const { children, maxHeightPercentage = 50, header="", innerBackgroundColor="purple" } = this.props;
        const maxHeight = `${maxHeightPercentage}vh`;

        return (
            <Box
                style={{
                    boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.5)',
                    backgroundColor: 'orange', // Set the background color to light blue
                    maxWidth: '375px', // Set the maximum width to the size of a standard smartphone
                    width: '100%', // Ensure the box takes up the full width of its container
                    margin: '0 auto 20px', // Center the box horizontally and add 20px margin at the bottom
                    padding: '5px', // Add 20px padding to the box
                    paddingBottom: '10px', // Add 10px padding to the bottom of the box
                    borderRadius: '10px', // Add rounded corners with a radius of 10px
                    alignSelf: 'center',
                    position: 'relative', // Add position relative to the group box
                }}
            >
                <Typography variant="h6">{header}</Typography>
                <div 
                    ref={(ref) => (this.contentRef = ref)}
                    style={{
                        boxShadow: 'inset 0px 0px 10px 5px rgba(0,0,0,0.5)',
                        padding: '10px', // Add 10px padding to the bottom of the content
                        backgroundColor: innerBackgroundColor, // Set the background color to light blue
                        maxHeight: maxHeight, // Set the maximum height to the specified percentage of the viewport height
                        overflowY: 'scroll', // Enable the vertical scroll bar when necessary
                        scrollbarWidth: 'none', // Hide the scrollbar in Firefox
                        msOverflowStyle: 'none', // Hide the scrollbar in IE
                        borderRadius: '10px', // Add rounded corners with a radius of 10px
                        margin: '5px', // Center the content horizontally
                    }}
                >
                    {children}
                </div>
            </Box>
        );
    }
}

export default GroupBox;