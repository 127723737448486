import React, { Component } from 'react';
import './App.css';
import { Typography, Box, IconButton, Collapse, Slider } from '@material-ui/core';
import { PlayArrow, Pause, Stop, Settings } from '@material-ui/icons';
import { ws } from './websocket';
import GroupBox from './GroupBox';
import { withParams } from './Wrappers';

class Player extends Component<
  { params: { pianoId: string } }, {
    pianoId: string;
    componentId: string;
    isPlaying: boolean;
    started: boolean;
    position: number;
    mediaFileName: string | null;
    beatPerView: number;
    isExpanded: boolean;
    speed: number;
    meidaFiles: string[];
  }, {}
> {
  constructor(props: { params: { pianoId: string } }) {
    super(props);
    this.state = {
      isPlaying: false,
      started: false,
      position: 0,
      mediaFileName: null,
      beatPerView: 1,
      isExpanded: false,
      speed: 60,
      meidaFiles: [],
      pianoId: props.params.pianoId,
      componentId: Math.random().toString(36).substring(7),
    };
  }

  componentWillUnmount() {
    const { pianoId, componentId } = this.state;
    ws.send(
      JSON.stringify({
        command: "unsubscribe",
        pianoId: pianoId,
      })
    );
    ws.removeCallbacks(componentId);

  }

  componentDidMount() {
    const { pianoId } = this.state;
    const { componentId } = this.state;

    // Connection
    ws.addMessageCallback(componentId, pianoId, (event) => {
      const { Command, Payload } = JSON.parse(event.data);
      switch (Command) {
        case 'files':
          const json = JSON.parse(Payload);
          this.setState({ meidaFiles: json });
          break;
        case 'position':
          this.setState({ position: Payload * 1000 });
          break;
        case 'startFile':
        case 'play':
          this.setState({ isPlaying: true, started: true });
          break;
        case 'pause':
          this.setState({ isPlaying: false, started: true });
          break;
        case 'stop':
          this.setState({ isPlaying: false, started: false, position: 0 });
          break;
        case 'setResolution':
        case 'getResolution':
          this.setState({ beatPerView: +(+Payload).toFixed(2) });
          break;
        case 'setSpeed':
        case 'getSpeed':
          this.setState({ speed: Math.round((+Payload) * 60 / 5) * 5 });
          break;
        default:
          break;
      }
    });

    ws.addCallbacks(
      componentId,
      () => {
        ws.send(
          JSON.stringify({
            command: 'getPlayableFiles',
            pianoId: pianoId,
          })
        );
        ws.send(
          JSON.stringify({
            command: 'subscribe',
            pianoId: pianoId,
          })
        );
      },
      (event) => {
        console.log('WebSocket connection closed:', event);
        // Handle your close event here
      },
      (error) => {
        console.log('WebSocket error:', error);
        // Handle your error here
      }
    );
  }

  handleBeatPerViewChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    if (typeof newValue !== 'number') {
      return;
    }
    const { pianoId } = this.state;
    this.setState({ beatPerView: newValue });
    ws.send(
      JSON.stringify({
        command: 'resolution',
        pianoId: pianoId,
        bpv: newValue,
      })
    );
  };

  handleSpeedChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    if (typeof newValue !== 'number') {
      return;
    }
    const { pianoId } = this.state;
    this.setState({ speed: newValue });
    ws.send(
      JSON.stringify({
        command: 'speed',
        pianoId: pianoId,
        speed: newValue,
      })
    );
  };


  handleSliderChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    if (typeof newValue !== 'number') {
      return;
    }
    const { pianoId } = this.state;
    this.setState({ position: newValue });
    ws.send(
      JSON.stringify({
        command: 'position',
        pianoId: pianoId,
        position: newValue / 1000,
      })
    );
  };

  handlePlay = () => {
    const { pianoId } = this.state;
    const { started, mediaFileName } = this.state;
    let api = 'start';
    if (started) {
      api = 'play';
    }

    ws.send(
      JSON.stringify({
        command: api,
        pianoId: pianoId,
        song: `${mediaFileName}`,
      })
    );
  };

  handlePause = () => {
    const { pianoId } = this.state;
    ws.send(
      JSON.stringify({
        command: 'pause',
        pianoId: pianoId,
      })
    );
  };

  handleStop = () => {
    const { pianoId } = this.state;
    ws.send(
      JSON.stringify({
        command: 'stop',
        pianoId: pianoId,
      })
    );
  };

  renderPosition = () => {
    const { started, position } = this.state;
    if (!started) {
      return null;
    }

    return (
      <>
        <Slider
          value={position}
          min={0}
          max={1000}
          onChange={this.handleSliderChange}
          aria-labelledby="position-slider"
        />
        <Typography variant="body1" style={{ marginBottom: '20px' }}>
          Position: {(position / 10).toFixed(0)}
        </Typography>
      </>
    );
  };

  renderStopButton = () => {
    const { started } = this.state;
    if (!started) {
      return;
    }
    return (
      <IconButton
        onClick={this.handleStop}
        style={{
          borderRadius: '50%',
          fontSize: '4rem',
          backgroundColor: 'white',
          marginLeft: '10%',
        }}
      >
        <Stop />
      </IconButton>
    );
  };

  renderPlayButton = () => {
    const { started, isPlaying } = this.state;
    if (started && isPlaying) {
      return;
    }
    return (
      <IconButton
        onClick={this.handlePlay}
        style={{ borderRadius: '50%', fontSize: '4rem', backgroundColor: 'white' }}
      >
        <PlayArrow />
      </IconButton>
    );
  };

  renderPauseButton = () => {
    const { started, isPlaying } = this.state;
    if (!started || !isPlaying) {
      return;
    }
    return (
      <IconButton
        onClick={this.handlePause}
        style={{ borderRadius: '50%', fontSize: '4rem', backgroundColor: 'white' }}
      >
        <Pause />
      </IconButton>
    );
  };

  handleToggleCollapse = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  renderSizeAndSpeed = () => {
    const { beatPerView, speed, isExpanded } = this.state;
    return (
      <>
        <IconButton onClick={this.handleToggleCollapse}>
          {isExpanded ? 'Hide ' : 'Show '}
          <Settings />
        </IconButton>
        <Collapse in={isExpanded}>
          <Slider
            value={beatPerView}
            min={0.25}
            max={2}
            step={0.25} // Set the step to 0.25
            onChange={this.handleBeatPerViewChange}
            aria-labelledby="position-slider"
          />
          <Typography variant="body1" style={{ marginBottom: '20px' }}>
            Beats Per View: {beatPerView} Beats
          </Typography>

          <Slider
            value={speed}
            min={10}
            max={200}
            step={5} // Set the step to 0.25
            onChange={this.handleSpeedChange}
            aria-labelledby="position-slider"
          />
          <Typography variant="body1" style={{ marginBottom: '20px' }}>
            Speed: {speed} BPM
          </Typography>
        </Collapse>
      </>
    );
  };

  renderMeidaFile = (file: string, index: number) => {
    return (
      <Box
        key={index}
        style={{
          backgroundColor: 'lightblue', // Set the background color to light blue
          boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.5)',
          borderRadius: '10px', // Add rounded corners with a radius of 10px
          alignSelf: 'center',
          marginBottom: '5%',
        }}
      >
        <IconButton
          onClick={() => {
            this.setState({ mediaFileName: file });
            this.handleStop();
          }}
        >
          {file}
        </IconButton>
      </Box>
    );
  };

  renderMediaFiles = () => {
    const { meidaFiles } = this.state;
    return (
      <GroupBox header='Media Files' maxHeightPercentage={50}>
        {meidaFiles.map((file, index) => {
          return this.renderMeidaFile(file, index);
        })}
      </GroupBox>
    );
  };

  renderMediaPlayer = () => {
    const { pianoId } = this.state;
    return (
      <GroupBox header={`${pianoId}:${this.state.mediaFileName}`} maxHeightPercentage={80}>
        {this.renderPosition()}
        {this.renderSizeAndSpeed()}
        {this.renderPlayButton()}
        {this.renderPauseButton()}
        {this.renderStopButton()}
      </GroupBox>
    );
  };

  render() {
    return (
      <>
        {this.renderMediaPlayer()}
        {this.renderMediaFiles()}
      </>
    );
  }
}

export default withParams(Player);
