interface OnOpenCallback {
    (args: any[]): void;
}

interface OnMessageCallback {
    (event: MessageEvent): void;
}

interface OnCloseCallback {
    (args: any[]): void;
}

interface OnErrorCallback {
    (args: any[]): void;
}

class WebSocketHandler {
    private onOpenCallbacks: { [id: string]: OnOpenCallback } = {};
    private onMessageCallbacks: { [id: string]: { [pianoId: string]: OnMessageCallback } } = {};
    private onCloseCallbacks: { [id: string]: OnCloseCallback } = {};
    private onErrorCallbacks: { [id: string]: OnErrorCallback } = {};
    private ws: WebSocket;

    constructor() {
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        this.ws = new WebSocket(`${protocol}//${window.location.hostname}/api/piano`);
        this.init();
    }

    addCallbacks(id: string, onOpen: OnOpenCallback, onClose: OnCloseCallback, onError: OnErrorCallback): void {
        this.onCloseCallbacks[id] = onClose;
        this.onErrorCallbacks[id] = onError;
        this.onOpenCallbacks[id] = onOpen;
        if (this.ws.readyState === WebSocket.OPEN) {
            onOpen([]);
        }
    }

    addMessageCallback(id: string, pianoId: string, onMessage: OnMessageCallback): void {
        this.onMessageCallbacks[id] = this.onMessageCallbacks[id] || {};
        this.onMessageCallbacks[id][pianoId] = onMessage;
    }

    init(): void {
        this.ws.onopen = (...args) => {
            Object.keys(this.onOpenCallbacks).forEach((id) => {
                try {
                    this.onOpenCallbacks[id](args);
                } catch (e) {
                    console.error("Error in onOpenCallbacks", e);
                }
            });
        };
        this.ws.onmessage = (event) => {
            Object.keys(this.onMessageCallbacks).forEach((id) => {
                try {
                    const { deviceId } = JSON.parse(event.data);
                    if (deviceId && this.onMessageCallbacks[id][deviceId]) {
                        this.onMessageCallbacks[id][deviceId](event);
                        return;
                    }
                    Object.keys(this.onMessageCallbacks[id]).forEach((deviceId) => {
                        this.onMessageCallbacks[id][deviceId](event);
                    });
                } catch (e) {
                    console.error("Error in onMessageCallbacks", e);
                }
            });
        };
        this.ws.onclose = (...args) => {
            Object.keys(this.onCloseCallbacks).forEach((id) => {
                try {
                    this.onCloseCallbacks[id](args);
                } catch (e) {
                    console.error("Error in onCloseCallbacks", e);
                }
            });
        };
        this.ws.onerror = (...args) => {
            Object.keys(this.onErrorCallbacks).forEach((id) => {
                try {
                    this.onErrorCallbacks[id](args);
                } catch (e) {
                    console.error("Error in onErrorCallbacks", e);
                }
            });
        };
    }

    send(data: any): void {
        this.ws.send(data);
    }

    removeCallbacks(id: string): void {
        delete this.onOpenCallbacks[id];
        delete this.onCloseCallbacks[id];
        delete this.onErrorCallbacks[id];
        delete this.onMessageCallbacks[id];
    }

    removeMessageCallback(id: string, pianoId: string): void {
        if (this.onMessageCallbacks[id]) {
            delete this.onMessageCallbacks[id][pianoId];
        }
    }
}

export const ws = new WebSocketHandler();
