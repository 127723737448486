import React from 'react';
import KeyShifter from './KeyShifter';
import PianoList from './PianoList';
import Player from './Player';
import { NavigationBar } from './Wrappers';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

function App() {
    return (
        <div
          className="App"
          style={{ width: '100vw', height: '100vh', backgroundColor: 'lightblue', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
        <Router>
            <NavigationBar/>
            <Routes>
                <Route path="/" element={<PianoList />} />
                <Route path="/piano/:pianoId" element={<Player />} />
                <Route path="/piano/:pianoId/shifter" element={
                        <KeyShifter />
                } />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
        </div>
    );
}

export default App;