import React, { Component } from 'react';
import './App.css';
import { Typography, Box, IconButton } from '@material-ui/core';
import { PlayArrow, Settings } from '@material-ui/icons';
import { ws } from './websocket';
import { withNavigation } from './Wrappers';
import GroupBox from './GroupBox';

class PianoList extends Component <{
  navigate: (to: string) => void
},{
  navigate: (arg0: string) => void;
  pianos: string[];
  componentId: string;
}, any>{

    constructor(props: {
    navigate: (arg0: string) => void;
    }) {
        super(props);
        this.state = {
          pianos: [],
          navigate: props.navigate,
          componentId: Math.random().toString(36).substring(7),
        };
      }

    componentWillUnmount() {
        const {componentId } = this.state;
        ws.removeCallbacks(componentId);
    
    }
    
      componentDidMount() {
        const { componentId } = this.state;
    
        // Connection
        ws.addMessageCallback(componentId, "", (event:{data: string}) => {
          const { Command, Payload } = JSON.parse(event.data);
          switch (Command) {
            case 'pianos':
              const json = JSON.parse(Payload);
              this.setState({ pianos: json });
              break;
            default:
              break;
          }
        });
        ws.addCallbacks(
            componentId,
            () => {
              ws.send(
                JSON.stringify({
                  command: 'listPianos',
                })
              );
            },
            (event: any) => {
              console.log('WebSocket connection closed:', event);
              // Handle your close event here
            },
            (error: any) => {
              console.log('WebSocket error:', error);
              // Handle your error here
            }
          );
    };
    renderPianoEntry = (pianoId: string, index: number) => {
        return (
          <Box
            key={index}
            style={{
              backgroundColor: 'lightblue', // Set the background color to light blue
              boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.5)',
              borderRadius: '10px', // Add rounded corners with a radius of 10px
              alignSelf: 'center',
              marginBottom: '5%',
            }}
          >
          <Typography variant="h6">
              {pianoId}</Typography>
            <IconButton
              onClick={() => {
                this.state.navigate(`/piano/${pianoId}`);
              }}
            >
                <PlayArrow />
            </IconButton>
            <IconButton
              onClick={() => {
                this.state.navigate(`/piano/${pianoId}/shifter`);
              }}
            >
                <Settings />
            </IconButton>
          </Box>
        );
      };
    
      render(){
        const { pianos } = this.state;
        return (
          <GroupBox header='Pianos' maxHeightPercentage={75}>
            {pianos.map((pianoId, index) => {
              return this.renderPianoEntry(pianoId, index);
            })}
          </GroupBox>
        );
      };
}

export default withNavigation(PianoList);